import { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import softwareApi, { IGetProductsReponse } from "../../../services/softwareApi";
import ErrorAlert from "../../atoms/ErrorAlert/ErrorAlert";
import Spinner from "../../atoms/Spinner/Spinner";
import "./ProductPage.scss";
import BackLink from "../../atoms/BackLink/BackLink";

const ProductPage = () => {

    const [loadingProduct, setLoadingProduct] = useState(true);

    const [productError, setProductError] = useState(null);

    const [product, setProduct] = useState<IGetProductsReponse>(null);

    const { groupId, productId } = useParams();

    const loadProduct = async () => {
        setLoadingProduct(true);
        setProduct(null);
        setProductError(null);
        await softwareApi.getProduct(productId)
            .then(data => {
                setLoadingProduct(false);
                setProduct(data.data);
            })
            .catch(error => {
                setLoadingProduct(false);
                setProductError(error.response?.data?.error ?? 'Error occurred.');
            })
    }

    useEffect(() => {
        loadProduct();
    }, []);

    return (
        <div className="product">
            {loadingProduct && <Spinner />}
            {product && (
                <>
                    <div>
                        <BackLink title={`Back to ${product.groupId$label}`} link={`/${product.groupId}`} />

                        <p className="product-name">
                            <span>{product.name}</span>
                            {product.maturity !== 'release' && <span className="product-maturity">{product.maturity}</span>}
                        </p>

                        {product.productTags && (
                            <div className="product-tags">
                                {product.productTags.map(t => (
                                    <span className="product-tag">{t.tag}</span>
                                ))}
                            </div>
                        )}

                        {!product.description && <p className="product-description">{product.summary}</p>}
                        {product.description && product.description.split('\n\n').map(l =>
                            <p className="product-description">{l}</p>
                        )}

                        {product.productScreenshots?.length > 0 && (
                            <>
                                <h2>Screenshots</h2>
                                {product.productScreenshots.map(s =>
                                    <Link to={s.file.url} target="_blank">
                                        <img className="product-screenshot" src={s.file.url} alt={s.file.name} />
                                    </Link>
                                )}
                            </>
                        )}
                    </div>
                    <div>

                        {product.releases && product.releases.map(r => (
                            <div className="product-release">
                                <p className="product-release-title">
                                    <span>{product.name} version {r.version}</span>
                                    <span className="product-release-maturity">({r.maturity})</span>
                                </p>
                                <p>Released {r.releaseDate}</p>
                                <ul>
                                    {r.releaseFiles?.map(f => (
                                        <Link to={f.file.url} download={f.file.name}>
                                            <li>
                                                <span className="product-release-file-name">{f.title}</span>
                                                <span className="product-release-file-platform">{f.platformId$label}</span>
                                                <span className="product-release-file-description">{f.description}</span>
                                            </li>
                                        </Link>
                                    ))}
                                </ul>


                            </div>
                        ))}

                        {product.productLinks && product.productLinks.map(l => (
                            <Link to={l.url}>
                                <div className="product-link">
                                    {l.summary && <p className="product-link-summary">{l.summary}</p>}
                                    <p className="product-link-anchor">
                                        {l.label}
                                    </p>
                                </div>
                            </Link>
                        ))}
                    </div>
                </>
            )}
            {productError && <ErrorAlert title='Cannot load product' message={productError} />}
        </div>
    )

}

export default ProductPage;