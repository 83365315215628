interface HillSoftwareLogoProps {
    width: number;
    height: number;
}

const HillSoftwareLogo = (props: HillSoftwareLogoProps) => {
    return (
        <img src={process.env.PUBLIC_URL + "/hillSoftwareLogo.png"} alt="Hill Software" width={props.width} height={props.height} />
    )
}

export default HillSoftwareLogo;