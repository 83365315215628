import config from "./config.json";
import axios, { AxiosInstance, AxiosResponse } from "axios";

export interface IGetDataParams {
    condition?: string;
    orderBy?: string;
    stripKeys?: number;
    stripChildKeys?: number;
    includeLinkedTables?: number;
    includeLookupLabels?: number;
    includeOptionSetLabels?: number;
    includeFileInfo?: number;
    stripForeignKeys?: number;
    recurse?: number;
}

export interface IGetGroupsReponse {
    id: number;
    title: string;
    intro: string;
    backgroundImage: string;
}

export interface IFileInfo {
    name: string;
    id: string;
    size: number;
    uploadDate: number;
    url: string;
}

export interface IGetProductsReponse {
    id: number;
    groupId: number;
    groupId$label: string;
    name: string;
    maturity: 'alpha' | 'beta' | 'release';
    visibility: string;
    summary: string;
    description: string;
    icon: string;
    icon$url: string;
    priority: number;
    productLinks: Array<{
        label: string;
        url: string;
        summary: string;
    }>;
    productScreenshots: Array<{
        name: string;
        caption: string;
        file: IFileInfo;
    }>;
    productTags: Array<{
        tag: string;
    }>,
    releases: Array<{
        maturity: string;
        releaseDate: string;
        version: string;
        releaseFiles: Array<{
            description: string;
            file: IFileInfo;
            platformId$label: string;
            title: string;
        }>;
    }>;
}

class SoftwareApi {

    private axios: AxiosInstance;

    private readonly baseUrl: string;

    constructor() {
        this.baseUrl = config.softwareApiBaseUrl;
        this.axios = axios.create({ baseURL: config.softwareApiBaseUrl });
    }

    async getGroups(): Promise<AxiosResponse<IGetGroupsReponse[]>> {
        let config = this.getRequestConfig();
        let params: IGetDataParams = {
            condition: `Visibility eq visible`,
            orderBy: 'Title'
        }
        return await this.axios.get(
            `/data/Group?${new URLSearchParams(params as any)}`,
            config
        );
    }

    async getProducts(groupId: string): Promise<AxiosResponse<IGetProductsReponse[]>> {
        let config = this.getRequestConfig();
        let params: IGetDataParams = {
            condition: `GroupId eq ${groupId} and Visibility eq visible`,
            orderBy: 'Priority',
            includeLinkedTables: 1,
            includeFileInfo: 1,
            recurse: 0
        };
        return await this.axios.get(
            `/data/Product?${new URLSearchParams(params as any)}`,
            config
        );
    }

    async getProduct(productId: string): Promise<AxiosResponse<IGetProductsReponse>> {
        let config = this.getRequestConfig();
        let params: IGetDataParams = {
            includeLinkedTables: 1,
            includeLookupLabels: 1,
            includeFileInfo: 1
        };
        return await this.axios.get(
            `/data/Product/${productId}?${new URLSearchParams(params as any)}`,
            config
        );
    }

    private getRequestConfig() {
        return {
            headers: {
                'Environment': `hill-software`
            }
        }
    }

}

const softwareApi = new SoftwareApi();

export default softwareApi;