import { Link } from "react-router-dom";
import HillSoftwareLogo from "../../atoms/HillSoftwareLogo/HillSoftwareLogo";
import './Header.scss';
import { useState, useEffect } from "react";
import softwareApi, { IGetGroupsReponse } from "../../../services/softwareApi";
import ErrorAlert from "../../atoms/ErrorAlert/ErrorAlert";
import Spinner from "../../atoms/Spinner/Spinner";
import GroupBar from "../../molecules/GroupBar/GroupBar";

const Header = () => {

    const [loadingGroups, setLoadingGroups] = useState(true);

    const [groupsError, setGroupsError] = useState(null);

    const [groups, setGroups] = useState<IGetGroupsReponse[]>(null);

    const loadGroups = async () => {
        setLoadingGroups(true);
        setGroups(null);
        setGroupsError(null);
        await softwareApi.getGroups()
            .then(data => {
                setLoadingGroups(false);
                setGroups(data.data);
            })
            .catch(error => {
                setLoadingGroups(false);
                setGroupsError(error.response?.data?.error ?? 'Error occurred.');
            })
    }

    useEffect(() => {
        loadGroups();
    }, []);

    return (
        <div id="header">
            <div id="header-logo">
                <Link to="/">
                    <HillSoftwareLogo width={100} height={80} />
                </Link>
                <Link to="/">Hill Software</Link>
            </div>
            <div id="header-groups">
                {loadingGroups && <Spinner />}
                {groups?.length === 0 && <p>There is no software available!</p>}
                {groups && <GroupBar groups={groups} />}
                {groupsError && <ErrorAlert title='Cannot load software groups' message={groupsError} />}
            </div>
        </div>
    )
}

export default Header;