import { IGetGroupsReponse } from "../../../services/softwareApi";
import GroupBarItem from "../../atoms/GroupBarItem/GroupBarItem";
import "./GroupBar.scss";

interface GroupBarProps {
    groups: IGetGroupsReponse[];
}

const GroupBar = (props: GroupBarProps) => {
    return (
        <div className="group-bar">
            {props.groups.map(i => <GroupBarItem label={i.title} link={i.id.toString()} />)}
        </div>
    )
}

export default GroupBar;