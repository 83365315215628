import { IGetProductsReponse } from "../../../services/softwareApi";
import ProductListItem from "../../atoms/ProductListItem/ProductListItem";
import "./ProductList.scss";

interface ProductListProps {
    products: IGetProductsReponse[];
}

const ProductList = (props: ProductListProps) => {
    return (
        <div className="product-list">
            {props.products.map(p => <ProductListItem product={p} />)}
        </div>
    )
}

export default ProductList;