import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import softwareApi, { IGetProductsReponse } from "../../../services/softwareApi";
import ErrorAlert from "../../atoms/ErrorAlert/ErrorAlert";
import Spinner from "../../atoms/Spinner/Spinner";
import ProductList from "../../molecules/ProductList/ProductList";
import InfoAlert from "../../atoms/InfoAlert/InfoAlert";
import { group } from "console";

const GroupPage = () => {

    const [loadingProducts, setLoadingProducts] = useState(true);

    const [productsError, setProductsError] = useState(null);

    const [products, setProducts] = useState<IGetProductsReponse[]>(null);

    const { groupId } = useParams();

    const loadProducts = async () => {
        setLoadingProducts(true);
        setProducts(null);
        setProductsError(null);
        await softwareApi.getProducts(groupId)
            .then(data => {
                setLoadingProducts(false);
                setProducts(data.data);
            })
            .catch(error => {
                setLoadingProducts(false);
                setProductsError(error.response?.data?.error ?? 'Error occurred.');
            })
    }

    useEffect(() => {
        loadProducts();
    }, [groupId]);

    return (
        <div>
            {loadingProducts && <Spinner />}
            {products?.length === 0 && <InfoAlert title="No products" message="There are no products available for this group!" />}
            {products && <ProductList products={products} />}
            {productsError && <ErrorAlert title='Cannot load products' message={productsError} />}
        </div>
    )

}

export default GroupPage;