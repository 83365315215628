import "./ProductListItem.scss";
import { IGetProductsReponse } from "../../../services/softwareApi";
import { Link } from "react-router-dom";

interface ProductListItemProps {
    product: IGetProductsReponse;
}

const ProductListItem = (props: ProductListItemProps) => {

    const screenshot = props.product.productScreenshots?.[0];

    return (
        <Link to={props.product.id.toString()}>
            <div className="product-list-item">
                {screenshot?.file && <img src={screenshot.file.url} alt={screenshot.file.name} />}
                <span className="product-list-item-name">{props.product.name}</span>
                {props.product.maturity !== 'release' &&
                    <span className="product-list-item-maturity">{props.product.maturity}</span>
                }
                <span className="product-list-item-summary">{props.product.summary}</span>
            </div>
        </Link>
    )
}

export default ProductListItem;