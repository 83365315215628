import { Link } from "react-router-dom";
import "./GroupBarItem.scss";

interface GroupBarItemProps {
    label: string;
    link: string;
}

const GroupBarItem = (props: GroupBarItemProps) => {
    return (
        <span className="group-bar-item">
            <Link to={props.link}>{props.label}</Link>
        </span>
    )
}

export default GroupBarItem;