import "./InfoAlert.scss";

interface InfoAlertProps {
    title: string;
    message: string;
}

const InfoAlert = (props: InfoAlertProps) => {
    return (
        <div className="message-info">
            <p className="message-info-title">{props.title}</p>
            <p className="message-info-message">{props.message}</p>
        </div>
    );
}

export default InfoAlert;