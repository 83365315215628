import { Link } from "react-router-dom";
import "./BackLink.scss";

interface BackLinkProps {
    title: string;
    link: string;
}

const BackLink = (props: BackLinkProps) => {
    return (
        <Link className="back-link" to={props.link}>{props.title}</Link>
    )
}

export default BackLink;